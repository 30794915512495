import React, { lazy } from 'react';
import { Routes, Route } from "react-router-dom";

const Navbar = lazy(() => import('./pages/Navbar'));
const Footer = lazy(() => import('./pages/Footer'));
const Home = lazy(() => import('./pages/home/Home'));
const AboutUs = lazy(() => import('./pages/about-us/AboutUs'));
const ContactUs = lazy(() => import('./pages/contact-us/ContactUs'));
const OurTeam = lazy(() => import('./pages/our-team/OurTeam'));
const Gallery = lazy(() => import('./pages/gallery/Gallery'));
const RegistrationLandowner = lazy(() => import('./pages/registration/RegistrationLandowner'));
const RegistrationDeveloper = lazy(() => import('./pages/registration/RegistrationDeveloper'));

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/registration-landowner" element={<RegistrationLandowner />} />
        <Route path="/registration-developer" element={<RegistrationDeveloper />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
